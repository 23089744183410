<template>
  <div>
    <el-card style="width: 30pc;margin-left: 30pc" v-show="formVisible">
      <el-form :model="formData" :label-position="labelPosition" label-width="200px">
            <el-form-item :label="$t('msg.dray.fromArea')" prop="srvPort" required >
              <el-select v-model="formData.srvPort" placeholder="Service Port" v-on:change="getToArea()">
                <el-option v-for="item in portList" :key="item.name" :label="item.name" :value="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('msg.dray.desType')" prop="desType" required  >
              <el-select v-model="formData.desType" placeholder="Destination Type" v-on:change="getToArea()">
                <el-option label="FBA" value="FBA"></el-option>
                <el-option label="City" value="City"></el-option>
              </el-select>
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item :label="$t('msg.dray.toArea')" prop="toArea" required >
              <el-select v-model="formData.toArea" placeholder="Destination" >
                <el-option v-for="item in toAreaList" :key="item.name" :label="item.name" :value="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('msg.dray.ctnrType')" prop="ctnrType" required >
              <el-select v-model="formData.ctnrType" placeholder="Container Size">
                <el-option label="20GP" value="20GP"></el-option>
                <el-option label="40GP" value="40GP"></el-option>
                <el-option label="40HQ" value="40HQ"></el-option>
                <el-option label="45HQ" value="45HQ"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('msg.dray.dropType')" prop="dropType" required >
              <el-select v-model="formData.dropType" placeholder="Drop Type">
                <el-option label="Live" value="Live"></el-option>
                <el-option label="Drop" value="Drop"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('msg.dray.mapType')" prop="mapType">
               <el-select v-model="formData.mapType" placeholder="Map Type" disabled>
                <el-option label="p10" value="p10"></el-option>
                <el-option label="p5" value="p5"></el-option>
               </el-select>
            </el-form-item>
            <el-divider></el-divider>
            <el-button type="primary" @click="getDrayQuote()" >{{$t('msg.rate.getQuote')}}</el-button>
          </el-form>
    </el-card>
    <el-card style="width: 100pc" v-show="drayRateShow">
        <el-button-group>
          <el-button type="primary" @click="newQuote()" style="margin-right: 30px">{{$t('msg.rate.reQuote')}}</el-button>
          <el-button type="primary" @click="download()" style="margin-right: 30px">{{$t('msg.rate.downloadCSV')}}</el-button>
          <el-button type="primary" @click="editShow()" style="margin-right: 30px">{{$t('msg.rate.createQuotePDF')}}</el-button>
          <el-button type="primary" @click="toOrder()" >{{$t('msg.rate.createOrder')}}</el-button>
        </el-button-group>
      <el-divider></el-divider>
      <el-descriptions :title="$t('msg.dray.costEstimate')" column=4>
        <el-descriptions-item :label="$t('msg.dray.fromArea')"><el-tag >{{drayCost.fromArea}}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('msg.dray.toArea')"><el-tag >{{drayCost.toArea}}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('msg.dray.lspName')"><el-tag >{{drayCost.lspName}}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('msg.dray.ctnrType')"><el-tag >{{drayCost.ctnrType}}</el-tag></el-descriptions-item>
      </el-descriptions>
      <el-row >
        <el-col :span="11" style="margin-right: 20px">
          <el-card>
          <el-descriptions :title="$t('msg.dray.costEstimateAP')">
            <el-descriptions-item :label="$t('msg.dray.drayage')">$ {{drayCostAP.drayage}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.fuelSurcharge')">$ {{drayCostAP.fuelSurcharge}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.chassisRent')">$ {{drayCostAP.chassisRent}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.liveUnload')">$ {{drayCostAP.liveUnload}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.bobTail')">$ {{drayCostAP.bobTail}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.pierPass')">$ {{drayCostAP.pierPass}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.terminalFee')">$ {{drayCostAP.terminalFee}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.portCongest')">$ {{drayCostAP.portCongest}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.cleanTruck')">$ {{drayCostAP.cleanTruck}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.normTotal')">$ {{drayCostAP.normTotal}}</el-descriptions-item>
          </el-descriptions>
          </el-card>
        </el-col>
        <el-col :span="11">
          <el-card>
          <el-descriptions :title="$t('msg.dray.costEstimateAR')">
            <el-descriptions-item :label="$t('msg.dray.drayage')">$ {{drayCost.drayage}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.fuelSurcharge')">$ {{drayCost.fuelSurcharge}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.chassisRent')">$ {{drayCost.chassisRent}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.liveUnload')">$ {{drayCost.liveUnload}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.bobTail')">$ {{drayCost.bobTail}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.pierPass')">$ {{drayCost.pierPass}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.terminalFee')">$ {{drayCost.terminalFee}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.portCongest')">$ {{drayCost.portCongest}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.cleanTruck')">$ {{drayCost.cleanTruck}}</el-descriptions-item>
            <el-descriptions-item :label="$t('msg.dray.normTotal')">$ {{drayCost.normTotal}}</el-descriptions-item>
          </el-descriptions>
          </el-card>
        </el-col>
      </el-row>
     <el-card v-show="orderVisible">
      <el-form :data="drayOrder" :label-position="orderLabelPosition" inline>
        <el-form-item :label="$t('msg.track.sslCarrier')" prop="sslCarrier">
          <el-input v-model="drayOrder.sslCarrier" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.track.mbl')" prop="mbl">
          <el-input v-model="drayOrder.mbl" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.track.ctnrId')" prop="ctnrId">
          <el-input v-model="drayOrder.ctnrId"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.track.ctnrSize')" prop="ctnrSize">
          <el-input v-model="drayOrder.ctnrSize" disabled></el-input>
        </el-form-item>
      </el-form>
      <el-form :data="drayOrder" :label-position="orderLabelPosition" inline>
        <el-form-item :label="$t('msg.track.sealNumber')" prop="sealNumber">
          <el-input v-model="drayOrder.sealNumber" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.track.pod')" prop="port" >
          <el-input v-model="drayOrder.port"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.track.eta')" prop="vesselETA">
          <el-date-picker :v-model="drayOrder.vesselETA" placeholder="Please Pick a Date" value-format="YYYYMMDD" disabled></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('msg.track.weight')" prop="ctnrWeight">
          <el-input-number v-model="drayOrder.ctnrWeight" controls-position="right" :step="1" disabled></el-input-number>
        </el-form-item>
        <el-form-item :label="$t('msg.track.wtUOM')" prop="wtUOM">
          <el-select v-model="drayOrder.wtUOM" placeholder="Weight UOM" disabled>
            <el-option label="lb" value="lb"></el-option>
            <el-option label="kg" value="kg"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :data="drayOrder" :label-position="orderLabelPosition" inline>
        <el-form-item prop="streetOne" :label="$t('msg.address.streetOne')">
          <el-input v-model="drayOrder.streetOne" :disabled="addressImportDisable"></el-input>
        </el-form-item>
        <el-form-item prop="streetTwo" :label="$t('msg.address.streetTwo')">
          <el-input v-model="drayOrder.streetTwo" :disabled="addressImportDisable"></el-input>
        </el-form-item>
        <el-form-item prop="city" :label="$t('msg.address.city')">
          <el-input v-model="drayOrder.city" disabled></el-input>
        </el-form-item>
        <el-form-item prop="state" :label="$t('msg.address.state')">
          <el-input v-model="drayOrder.state" :disabled="addressImportDisable"></el-input>
        </el-form-item>
        <el-form-item prop="zip" :label="$t('msg.address.zip')">
          <el-input v-model="drayOrder.zip" :disabled="addressImportDisable"></el-input>
        </el-form-item>
        <el-button type="text" @click="getAddress()" style="margin-top: 30px" :disabled="addressImportDisable">{{$t('msg.address.importAddress')}}</el-button>
      </el-form>
      <el-form :model="orderFormData" :label-position="orderLabelPosition" inline>
         <el-form-item :label="$t('msg.rate.clientName')" prop="clientName">
           <el-select v-model="orderFormData.clientName" value-key="name" placeholder="Select" @change="getPayTerm">
             <el-option v-for="item in clientList" :key="item.name" :label="item.name" :value="item" />
           </el-select>
         </el-form-item>
         <el-form-item :label="$t('msg.rate.l3Owner')" prop="l3Owner" required>
           <el-select v-model="orderFormData.l3Owner" placeholder="L3 Owner">
             <el-option label="Surpath" value="Surpath"></el-option>
             <el-option label="E2E" value="E2E"></el-option>
           </el-select>
         </el-form-item>
         <el-form-item :label="$t('msg.rate.pageRemark')" prop="pageRemark">
           <el-input v-model="orderFormData.pageRemark"></el-input>
         </el-form-item>
         <el-form-item :label="$t('msg.rate.payTerm')" prop="payTerm">
           <el-input v-model="orderFormData.payTerm" disabled></el-input>
         </el-form-item>
         <el-form-item :label="$t('msg.account.dueDate')" prop="dueDate" >
           <el-date-picker :v-model="orderFormData.dueDate" placeholder="Please Pick a Date" value-format="YYYYMMDD"></el-date-picker>
         </el-form-item>
         <el-button type="primary" v-on:click="createOrder()" style="float: right;margin-top: 30px">{{$t('msg.rate.generateOrder')}}</el-button>
       </el-form>
     </el-card>
     <el-collapse v-model="collapse" accordion>
            <el-collapse-item name="1">
              <template #title >
                <span style="color: #0ea5e9">{{$t('msg.dray.reviewFullCard')}}</span>
              </template>
              <el-button-group style="margin-bottom: 20px">
                <el-button type="primary" @click="rcEdit()" style="margin-right: 30px">{{$t('msg.rate.rcEdit')}}</el-button>
                <el-button type="primary" @click="editComplete()" >{{$t('msg.rate.editComplete')}}</el-button>
              </el-button-group>
              <el-table :data="drayRate" border>
                <el-table-column prop="item" :label="$t('msg.rate.item')" align="center">
                  <template #default="scope">
<!--                    <el-input v-model="scope.row.item" size="small" Style="font-size: small" v-if="scope.$index+myFlag===-1"></el-input>-->
                    <el-select v-model="scope.row.item" placeholder="Cost Category" size="small" Style="font-size: small" v-if="scope.$index+myFlag===-1">
                      <el-option label="Drayage" value="Drayage"></el-option>
                      <el-option label="Pass Through" value="Pass Through"></el-option>
                      <el-option label="Std Ad Hoc" value="Std Ad Hoc"></el-option>
                      <el-option label="Criteria" value="Criteria"></el-option>
                    </el-select>
                    <span v-else>{{scope.row.item}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="c1" :label="$t('msg.rate.c1')" align="center">
                  <template #default="scope">
                    <el-input v-model="scope.row.c1" size="small" Style="font-size: small" v-if="scope.$index+myFlag===-1"></el-input>
                    <span v-else>{{scope.row.c1}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="c2" :label="$t('msg.rate.c2')" align="center">
                  <template #default="scope">
                    <el-input v-model="scope.row.c2" size="small" Style="font-size: small" v-if="scope.$index+myFlag===-1"></el-input>
                    <span v-else>{{scope.row.c2}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="ratePerUOM" :label="$t('msg.rate.ratePerUOM')" align="center">
                  <template #default="scope">
                    <el-input-number v-model="scope.row.ratePerUOM" size="small" controls-position="right" :step="1" v-if="scope.$index+myFlag===-1"></el-input-number>
                    <span v-else-if="scope.row.item==='Criteria'">{{scope.row.ratePerUOM}}</span>
                    <span v-else>$ {{scope.row.ratePerUOM}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="adjust" :label="$t('msg.rate.adjust')" align="center">
                  <template #default="scope">
                    <el-input-number v-model="scope.row.adjust" size="small" controls-position="right" :step="1" v-if="scope.$index+myFlag===-1"></el-input-number>
                    <span v-else-if="scope.row.item==='Criteria'">{{scope.row.adjust}}</span>
                    <span v-else-if="scope.row.adjust===-1">{{scope.row.adjust}}</span>
                    <span v-else>$ {{scope.row.adjust}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="uom" :label="$t('msg.rate.uom')" align="center">
                  <template #default="scope">
                    <el-input v-model="scope.row.uom" size="small" Style="font-size: small" v-if="scope.$index+myFlag===-1"></el-input>
                    <span v-else>{{scope.row.uom}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="notes" :label="$t('msg.rate.notes')" align="center">
                  <template #default="scope">
                    <el-input v-model="scope.row.notes" size="small" Style="font-size: small" v-if="scope.$index+myFlag===-1"></el-input>
                    <span v-else>{{scope.row.notes}}</span>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" :label="$t('msg.rate.Operations')" width="200px" align="center" v-if="rcEditVisible">
                  <template #default="scope">
                    <el-button @click="toEditable(scope.$index)" :icon="editIcon"/>
                    <el-button type="primary" @click="addRow(scope.$index)" :icon="addIcon" />
                    <el-button type="danger" @click="delHandel(scope.$index)" :icon="delIcon" />
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
    </el-card>
    <el-dialog title="My Address Book" v-model="addressVisible" width="60%" center draggable>
      <el-button type="primary" style="float: right;margin-bottom: 20px;margin-right: 40px" @click="createAddress()">{{$t('msg.address.newAddress')}}</el-button>
      <el-table :data="myAddress">
        <el-table-column prop="personName" :label="$t('msg.address.personName')" size="small"></el-table-column>
        <el-table-column prop="streetOne" :label="$t('msg.address.streetOne')" size="small"></el-table-column>
<!--        <el-table-column prop="streetTwo" :label="$t('msg.address.streetTwo')"></el-table-column>-->
        <el-table-column prop="city" :label="$t('msg.address.city')" size="small"></el-table-column>
        <el-table-column prop="state" :label="$t('msg.address.state')" size="small"></el-table-column>
        <el-table-column prop="zip" :label="$t('msg.address.zip')" size="small"></el-table-column>
        <el-table-column fixed="right" :label="$t('msg.rate.Operations')" align="center">
          <template #default="scope">
            <el-button link type="text" size="small" @click="importRow(scope.row)">{{$t('msg.address.importAddress')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="New Address" v-model="newAddressVisible" width="30%" center draggable>
      <el-form :data="newAddress">
        <el-form-item prop="personName" :label="$t('msg.address.personName')" style="width: 20pc">
          <el-input v-model="newAddress.personName"></el-input>
        </el-form-item>
        <el-form-item prop="streetOne" :label="$t('msg.address.streetOne')" style="width: 20pc">
          <el-input v-model="newAddress.streetOne"></el-input>
        </el-form-item>
        <el-form-item prop="city" :label="$t('msg.address.city')" style="width: 20pc" >
          <el-input v-model="newAddress.city" disabled></el-input>
        </el-form-item>
        <el-form-item prop="state" :label="$t('msg.address.state')" style="width: 20pc">
          <el-select v-model="newAddress.state" placeholder="Select">
            <el-option v-for="item in states" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item prop="zip" :label="$t('msg.address.zip')" style="width: 20pc">
          <el-input v-model="newAddress.zip"></el-input>
        </el-form-item>
        <el-button type="primary" style="float: right;margin-bottom: 20px;margin-right: 20px" @click="insertAddress()">Submit</el-button>
      </el-form>
    </el-dialog>
    <el-dialog title="Quote Basic Information" v-model="editFormVisible" v-on:close="resetForm()" width="30%" center draggable>
      <el-form :model="editFormData" >
        <el-form-item :label="$t('msg.rate.clientName')" prop="clientName" style="width: 350px">
          <el-select v-model="editFormData.clientName" placeholder="Select" style="width: 120px">
            <el-option v-for="item in clientList" :key="item.name" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.l3Owner')" prop="l3Owner" required style="width: 420px">
          <el-select v-model="editFormData.l3Owner" placeholder="L3 Owner">
            <el-option label="Surpath" value="Surpath"></el-option>
            <el-option label="E2E" value="E2E"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.pageRemark')" prop="pageRemark" required style="width: 350px">
          <el-input v-model="editFormData.pageRemark"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.startDate')" prop="startDate" required >
          <el-date-picker v-model="editFormData.startDate" placeholder="Please Pick a Date" value-format="YYYYMMDD"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.endDate')" prop="endDate" required >
          <el-date-picker v-model="editFormData.endDate" placeholder="Please Pick a Date" value-format="YYYYMMDD"></el-date-picker>
        </el-form-item>
      </el-form>
      <el-button type="primary" v-on:click="pdfDownload()" style="float: right">Submit</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { Edit, Delete, DocumentAdd } from '@element-plus/icons-vue'
import axios from 'axios'
import { emptyDrayCostDetail } from '@/constant'
import { csvDownload, pdfGenerate } from '@/utils/download'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'drayQuote',
  setup() {
    const orderLabelPosition = ref('top')
    const addressImportDisable = ref(false)
    const editIcon = Edit
    const delIcon = Delete
    const addIcon = DocumentAdd
    const formVisible = ref(true)
    const labelPosition = ref('left')
    const orderVisible = ref(false)
    const addressVisible = ref(false)
    const rcEditVisible = ref(false)
    const newAddressVisible = ref(false)
    const myFlag = ref(0)
    const portList = ref([])
    const toAreaList = ref([])
    const myAddress = ref([])
    const formData = ref({ srvPort: 'LGB', desType: 'City', toArea: '', ctnrType: '', dropType: '', mapType: 'p10' })
    const drayRate = ref([])
    const drayRateAP = ref([])
    const drayOrder = ref({ id: 0, sslCarrier: '', mbl: '', ctnrId: '', ctnrSize: '', sealNumber: 'NA', vesselETA: '', ctnrWeight: 0, wtUOM: 'lb', port: '', streetOne: '', streetTwo: '', city: '', state: '', zip: '', attr: '' })
    const newAddress = ref({ personName: '', streetOne: '', city: '', state: '', zip: '' })
    const newCostDetail = emptyDrayCostDetail
    const drayCost = ref(newCostDetail)
    const drayCostAP = ref(newCostDetail)
    const drayRateShow = ref(false)
    const orderFormData = ref({ clientName: '', l3Owner: '', pageRemark: 'NA', payTerm: '', dueDate: '', clientTag: '' })
    const editFormData = ref({ clientName: '', l3Owner: '', startDate: '', endDate: '', pageRemark: '' })
    const editFormVisible = ref(false)
    const clientList = ref([])
    const states = ref([])
    const collapse = ref('')
    const curUser = localStorage.getItem('curUser')
    const router = useRouter()
    onMounted(() => {
      axios.get(process.env.VUE_APP_BASE + '/dray/quotePlus').then((response) => {
        portList.value = response.data
      })
      getToArea()
      getClientList()
    })
    const rcEdit = () => {
      rcEditVisible.value = true
    }
    const editComplete = () => {
      collapse.value = '0'
      rcEditVisible.value = false
    }
    const resetForm = () => {
      editFormData.value = { clientName: '', oldClient: 'New Client', l3Owner: '', startDate: '', endDate: '', pageRemark: '' }
    }
    const newQuote = () => {
      location.reload()
    }
    const importRow = (row) => {
      drayOrder.value.streetOne = row.streetOne
      drayOrder.value.city = row.city
      drayOrder.value.state = row.state
      drayOrder.value.zip = row.zip
      addressVisible.value = false
    }
    const toOrder = () => {
      if (formData.value.desType === 'FBA') {
        const param = 'fbaCode=' + formData.value.toArea
        const reqURL = process.env.VUE_APP_BASE + '/getAddressByFBAcode'
        axios.post(reqURL, param).then((response) => {
          console.log(response.data)
          drayOrder.value.streetOne = response.data.streetOne
          drayOrder.value.city = response.data.city
          drayOrder.value.state = response.data.state
          drayOrder.value.zip = response.data.zip
        }).catch((response) => {
          console.log(response)
        })
      } else {
        drayOrder.value.city = formData.value.toArea
      }
      /* collapse.value = '1' */
      orderVisible.value = true
    }
    const createOrder = () => {
      if (drayOrder.value.sslCarrier === '' || drayOrder.value.mbl === '' || drayOrder.value.ctnrId === '') {
        alert('Carrier, MBL and Container Id are required!')
        return false
      }
      const para = 'mbl=' + drayOrder.value.mbl
      const reqURL = process.env.VUE_APP_BASE + '/dray/orderScheduleValidate'
      axios.post(reqURL, para).then((response) => {
        if (response.data !== 'Normal') {
          confirm(response.data + '------Still Want to Generate Order?')
        }
        const param = 'drayRates=' + JSON.stringify(drayRate.value) + '&drayOrder=' + JSON.stringify(drayOrder.value) + '&drayCostDetail=' + JSON.stringify(drayCost.value) +
          '&contractIssuer=' + orderFormData.value.l3Owner + '&payTerm=' + orderFormData.value.payTerm + '&curUser=' + curUser + '&clientTag=' + orderFormData.value.clientTag +
          '&drayCostAP=' + JSON.stringify(drayCostAP.value) + '&drayRateAP=' + JSON.stringify(drayRateAP.value)
        const reqURL = process.env.VUE_APP_BASE + '/dray/newOrder'
        axios.post(reqURL, param).then((response) => {
          if (response.data === 'Successful') {
            router.push('/record/orders')
          } else {
            alert(response.data)
            return false
          }
        }).catch((response) => {
          console.log(response)
        })
      }).catch((response) => {
        console.log(response)
      })
    }
    const toEditable = (id) => {
      myFlag.value = -(id + 1)
    }
    const addRow = (id) => {
      const newLine = { item: '', c1: '', c2: '', ratePerUOM: 0, adjust: 0, uom: '', notes: '' }
      drayRate.value.splice(id + 1, 0, newLine)
      myFlag.value = -(id + 2)
    }
    const delHandel = (id) => {
      myFlag.value = 0
      drayRate.value.splice(id, 1)
    }
    const getAddress = () => {
      const param = 'curUser=' + curUser + '&city=' + formData.value.toArea
      const reqURL = process.env.VUE_APP_BASE + '/getAddressByCity'
      axios.post(reqURL, param).then((response) => {
        myAddress.value = response.data
        addressVisible.value = true
      }).catch((response) => {
        console.log(response)
      })
    }
    const getToArea = () => {
      addressImportDisable.value = formData.value.desType === 'FBA'
      const param = 'port=' + formData.value.srvPort + '&toType=' + formData.value.desType
      const reqURL = process.env.VUE_APP_BASE + '/dray/getDestList'
      axios.post(reqURL, param).then((response) => {
        toAreaList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const getDrayQuote = () => {
      const param = 'srvPort=' + formData.value.srvPort + '&desType=' + formData.value.desType + '&toArea=' +
        formData.value.toArea + '&ctnrType=' + formData.value.ctnrType + '&dropType=' + formData.value.dropType + '&mapType=' + formData.value.mapType
      const reqURL = process.env.VUE_APP_BASE + '/dray/getSinglePortQuote'
      axios.post(reqURL, param).then((response) => {
        drayRate.value = response.data.drayRateList
        drayCost.value = response.data.drayCostDetail
        drayCostAP.value = response.data.drayCostAP
        drayRateAP.value = response.data.drayRatesAP
        drayOrder.value.ctnrSize = formData.value.ctnrType
        drayOrder.value.port = formData.value.srvPort
        drayOrder.value.attr = response.data.drayCostDetail.ctnrid
        drayRateShow.value = true
        formVisible.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    const download = () => {
      const downloadName = 'Dray-Quote.csv'
      const param = 'drayRates=' + JSON.stringify(drayRate.value) + '&downloadName=' + downloadName
      const url = '/dray/csvQuoteDL'
      csvDownload(url, param, downloadName)
    }
    const getClientList = () => {
      const param = 'curName=' + localStorage.getItem('curUser')
      const reqURL = process.env.VUE_APP_BASE + '/ocean/getUniqueClientName'
      axios.post(reqURL, param).then((response) => {
        clientList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const getPayTerm = (val) => {
      orderFormData.value.clientTag = val.name + '-' + val.attr.split('-')[0]
      const param = 'clientCode=' + val.attr.split('-')[0]
      const reqURL = process.env.VUE_APP_BASE + '/getPayTermByClientCode'
      console.log(param)
      axios.post(reqURL, param).then((response) => {
        orderFormData.value.payTerm = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const createAddress = () => {
      getStates()
      newAddress.value.city = drayOrder.value.city
      newAddressVisible.value = true
    }
    const getStates = () => {
      axios.get(process.env.VUE_APP_BASE + '/getUS50States').then((response) => {
        states.value = response.data
      })
    }
    const insertAddress = () => {
      if (newAddress.value.personName === '' || newAddress.value.streetOne === '' || newAddress.value.state === '' || newAddress.value.zip === '') {
        alert('Every field in this form is required!')
        return false
      }
      const streetOne = newAddress.value.streetOne.replaceAll(',', ' ')
      const param = 'curUser=' + curUser + '&city=' + newAddress.value.city + '&personName=' + newAddress.value.personName + '&streetOne=' + streetOne +
                   '&state=' + newAddress.value.state.split('-')[1] + '&zip=' + newAddress.value.zip
      const reqURL = process.env.VUE_APP_BASE + '/insertAddress'
      axios.post(reqURL, param).then((response) => {
        myAddress.value = response.data
        newAddressVisible.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    const editShow = () => {
      editFormVisible.value = true
    }
    const pdfDownload = () => {
      const param = 'drayRates=' + JSON.stringify(drayRate.value) + '&clientName=' + editFormData.value.clientName + '&l3Owner=' + editFormData.value.l3Owner +
        '&startDate=' + editFormData.value.startDate + '&endDate=' + editFormData.value.endDate + '&pageRemark=' + editFormData.value.pageRemark +
        '&curUser=' + curUser + '&lspName=' + drayCost.value.lspName + '&srvPort=' + drayCost.value.fromArea + '&desType=' + formData.value.desType + '&drayCost=' + JSON.stringify(drayCost.value)
      const myUrl = '/dray/pdfQuoteDL'
      const downloadName = editFormData.value.l3Owner + '-Quote.pdf'
      editFormVisible.value = false
      pdfGenerate(myUrl, param, downloadName)
    }
    return { rcEditVisible, addressImportDisable, orderLabelPosition, addIcon, delIcon, editIcon, myFlag, states, addressVisible, newAddressVisible, newAddress, getPayTerm, insertAddress, createAddress, getStates, getClientList, editComplete, rcEdit, createOrder, labelPosition, orderFormData, myAddress, importRow, formVisible, portList, toAreaList, formData, drayRate, drayRateAP, drayOrder, drayRateShow, orderVisible, drayCost, drayCostAP, curUser, getAddress, toEditable, addRow, delHandel, newQuote, resetForm, getToArea, getDrayQuote, download, pdfDownload, toOrder, editFormVisible, editShow, clientList, collapse, editFormData }
  }
})
</script>
